import 'lazysizes'
import Alpine from 'alpinejs'
import Glide from '@glidejs/glide'

if(document.querySelector('.glide')) {
  const sliders = document.querySelectorAll('.glide')

  for(let i = 0; i < sliders.length; i++) {
    const glide = new Glide(sliders[i], {
      type: 'carousel',
      gap: 0
    })
    glide.mount()
  }
}

window.Alpine = Alpine
Alpine.start()
